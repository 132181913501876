import React from 'react'
import Layout from '../components/Layout'

const Now = () => {
    return (
        <Layout pageTitle="Now">
          <p>This is a <a href="https://nownownow.com/about">now</a> page.</p>
          <p>Currently in Mumbai, India. Taking some time off from work. Going all-in in crypto and web3. Learning Ethereum development</p>
          <p>Reading: Expected Returns</p>
        </Layout>
      )
  }

export default Now
